<template>
  <div>
    <loading v-if="!user"></loading>
    <div class="container" v-else>
      <div class="row">
        <div class="col-md-3">
          <div class="card border-0 shadow-none bg-light">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="fw-bold">{{ user.name }}</h5>
                  <small class="fw-bold">{{ user.email }}</small>
                  <p>
                    <small>Joined: {{ user.created_at | formatDate }}</small>
                  </p>
                  <p class="fw-bold text-capitalize">
                    Role: {{ user.user_roles[0] }}
                  </p>

                  <div class="bg-white p-2">
                    <p class="mb-1">Update user role</p>
                    <select
                      class="form-control text-capitalize"
                      v-model="user.user_roles[0]"
                      @change="updateUserRole"
                    >
                      <option
                        class=""
                        v-for="r in roles"
                        :key="r.id"
                        :value="r.name"
                      >
                        {{ r.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card border-0 shadow-none">
            <div class="card-body">
              <p class="alert alert-success">
                This user has permissions to do the following
              </p>
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item text-capitalize"
                  v-for="p in user.user_permissions"
                  :key="user.id + '-' + p"
                >
                  {{ p }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      user: null,
      roles: [],
    };
  },
  methods: {
    updateUserRole() {
      if (confirm("Are you sure you wish to change this users role?")) {
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/users/" +
              this.user.id +
              "/update-role",
            {
              role: this.user.user_roles[0],
            }
          )
          .then(({ data }) => {
            console.log(data);
            this.fetchUser();
          });
      }
    },
    fetchRoles() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/roles")
        .then(({ data }) => {
          this.roles = data;
        });
    },
    fetchUser() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/users/" + this.$route.params.id)
        .then(({ data }) => {
          this.user = data;
        });
    },
  },
  mounted() {
    this.fetchRoles();
    this.fetchUser();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style>
</style>